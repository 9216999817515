import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PageData } from 'st-recipes-definitions/page-data';
import { PathsUtil } from 'st-recipes-definitions/paths-util';
import { SeoDataCollector } from 'st-recipes-definitions/seo-data-collector';
import { setCookie } from 'typescript-cookie';
import { SearchResponse } from '../../shared/search-response';
import { CONSTANTS } from './../../shared/constants';
import { Locale } from './../../st-recipes-definitions/raw-data-types';
import { SidenavService } from './sidenav.service';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  // Subject vs BehaviorSubject: https://stackoverflow.com/questions/43348463/what-is-the-difference-between-subject-and-behaviorsubject
  private pageData = new BehaviorSubject<PageData | null>(/* initialValue= */ null);

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private titleService: Title,
    private metaService: Meta,
    @Inject(DOCUMENT) private documentService: Document,
    private http: HttpClient,
    private sidenavService: SidenavService,
    router: Router) {
    // Subscribe to routing events to refresh the page data.
    router.events.subscribe(val => {
      // Fetch new value on navigation end.
      if (val instanceof NavigationEnd) {
        // Firebase functions only accept cookie with name equals to `__session`. It strips away all other cookies for
        // caching purposes. Ref: 
        // - https://firebase.google.com/docs/hosting/manage-cache#using_cookies
        // - https://firebase.google.com/docs/auth/admin/manage-cookies#create_session_cookie
        if (typeof document !== 'undefined') {
          setCookie('__session', JSON.stringify({ [CONSTANTS.LANG_COOKIE_NAME]: PathsUtil.getLocale(val.url) }), { expires: /* day= */ 365, path: '/' });
        }

        // Scroll to the top after navigation.
        if (typeof window !== 'undefined') {
          window.scrollTo(0, 0);
        }

        // Close sidenav everytime the navigation has finished.
        this.sidenavService.close();

        // Fetch the data. There won't be any duplicate fetches between server and client side, since the HTTP is cached
        // during hydration. See https://angular.io/guide/hydration
        this.fetchPageData(val.url);
      }
    });
  }

  /** 
   * Gets the page data. The page data might be null when it's dev environment, or when it's client-side execution
   * after SSR (in SSR, even though the UI was rendered server-side, the client-side code will still be sent to the
   * client and get executed, this includes the data service initialization, which means there won't be any page data
   * during the initialization. If the subscriber subscribes to the page data, then it'll always get null for the page
   * data on client-side).
   */
  getPageData(): BehaviorSubject<PageData | null> {
    return this.pageData;
  }

  postRating(value: number) {
    this.http.post(`/${CONSTANTS.ENDPOINT_POST_RATING}`, { value }, { responseType: 'text' }).subscribe();
  }

  postFeedback(value: string) {
    this.http.post(`/${CONSTANTS.ENDPOINT_POST_FEEDBACK}`, { value }, { responseType: 'text' }).subscribe();
  }

  async getSearchResults(locale: Locale, page: number, query: string, sortOption: string, filterTagIds: string[]): Promise<SearchResponse> {
    return await firstValueFrom(
      this.http.get<SearchResponse>(`${environment.apiHost}/${CONSTANTS.ENDPOINT_SEARCH}`, {
        params: {
          [CONSTANTS.QUERY_PARAM_LOCALE]: locale,
          [CONSTANTS.QUERY_PARAM_PAGE_NUMBER]: page,
          [CONSTANTS.QUERY_PARAM_SEARCH_QUERY]: query,
          [CONSTANTS.QUERY_PARAM_SORT_OPTION]: sortOption,
          [CONSTANTS.QUERY_PARAM_FILTER_TAGS_IDS]: filterTagIds,
        },
      })) as SearchResponse;
  }

  private fetchPageData(path: string) {
    this.http.get(`${environment.apiHost}/${CONSTANTS.ENDPOINT_GET_PAGE_DATA}`, {
      params: {
        p: PathsUtil.sanitizePath(path),
      }
    }).subscribe(pageData => {
      this.pageData.next(pageData);
      this.updateSeoTags(path, pageData);
    }, (err) => {
      console.error(`Fetch page data for path ${path} failed!`);
      console.error(err);
      // Add info token to let the SSR side know that the fetch page data was failed.
      this.documentService.querySelector(`script[id="${CONSTANTS.SSR_INFO_PLACEHOLDER_SCRIPT_ID}"]`)!.innerHTML = JSON.stringify({ v: CONSTANTS.SSR_INFO_FETCH_DATA_FAILED });
    });
  }

  private updateSeoTags(path: string, pageData: PageData) {
    // We need to get the locale manually from the path string since there's no injectable instance of ng routes in this service.
    // This service is injected at root, it's a singleton.
    const locale = PathsUtil.getLocale(path);
    const seoData = SeoDataCollector.collectSeoData(locale, path, pageData);

    // Title and description
    this.titleService.setTitle(seoData.title);
    this.metaService.updateTag({ name: 'description', content: seoData.description });

    // Canonical link
    this.documentService.querySelector(`link[rel="canonical"]`)!.setAttribute('href', seoData.canonicalUrl);
    this.documentService.documentElement.lang = locale;

    // Social Media tags
    this.metaService.updateTag({ property: 'og:description', content: seoData.ogDescription });
    this.metaService.updateTag({ property: 'og:image', content: seoData.ogImage });
    this.metaService.updateTag({ property: 'og:locale', content: seoData.ogLocale });
    this.metaService.updateTag({ property: 'og:site_name', content: seoData.ogSiteName });
    this.metaService.updateTag({ property: 'og:title', content: seoData.ogTitle });
    this.metaService.updateTag({ property: 'og:type', content: seoData.ogType });
    this.metaService.updateTag({ property: 'og:url', content: seoData.ogUrl });
    this.metaService.updateTag({ name: 'twitter:card', content: seoData.twitterCard });
    this.metaService.updateTag({ name: 'twitter:domain', content: seoData.twitterDomain });
    this.metaService.updateTag({ name: 'twitter:title', content: seoData.twitterTitle });
    this.metaService.updateTag({ name: 'twitter:description', content: seoData.twitterDescription });

    // Rich results
    // <script type="application/ld+json">{{RICH_RESULTS_JSON}}</script>
    this.documentService.querySelector('script[type="application/ld+json"]')!.innerHTML = JSON.stringify(seoData.richResults);
  }
}
