import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  standalone: true,
  selector: 'app-svg',
  templateUrl: './svg.component.html',
  styleUrls: ['./svg.component.scss'],
  imports: [
    CommonModule,
  ],
})
export class SvgComponent {
  @Input() shape?: 'burger-menu' | 'clock' | 'cutlery' | 'facebook' | 'flag-id' | 'flag-us' | 'folder' | 'instagram' | 'mail' | 'pinterest' | 'search' | 'twitter' | 'feedback' | 'sort-icon' | 'youtube';
  @Input() width?: string;
  @Input() height?: string;
}
