<!-- All SVGs' contents are defined in app HTML inside SVG's <defs> tag. -->
<span [ngSwitch]="shape">
  <svg *ngSwitchCase="'burger-menu'" viewBox="0 0 100 80" [ngStyle]="{width, height}">
    <use xlink:href="#burger-menu" href="#burger-menu"></use>
  </svg>
  <svg *ngSwitchCase="'clock'" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 488 488" style="enable-background:new 0 0 488 488;" [ngStyle]="{width, height}">
    <use xlink:href="#clock" href="#clock"></use>
  </svg>
  <svg *ngSwitchCase="'cutlery'" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 470.026 470.026" enable-background="new 0 0 470.026 470.026" [ngStyle]="{width, height}">
    <use xlink:href="#cutlery" href="#cutlery"></use>
  </svg>
  <svg *ngSwitchCase="'facebook'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" [ngStyle]="{width, height}">
    <use xlink:href="#facebook" href="#facebook"></use>
  </svg>
  <svg *ngSwitchCase="'flag-id'" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 55.7 38.9" style="enable-background:new 0 0 55.7 38.9" [ngStyle]="{width, height}">
    <use xlink:href="#flag-id" href="#flag-id"></use>
  </svg>
  <svg *ngSwitchCase="'flag-us'" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 55.2 38.4" style="enable-background:new 0 0 55.2 38.4" [ngStyle]="{width, height}">
    <use xlink:href="#flag-us" href="#flag-us"></use>
  </svg>
  <svg *ngSwitchCase="'folder'" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 298.757 298.757" style="enable-background:new 0 0 298.757 298.757;" [ngStyle]="{width, height}">
    <use xlink:href="#folder" href="#folder"></use>
  </svg>
  <svg *ngSwitchCase="'instagram'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" [ngStyle]="{width, height}">
    <use xlink:href="#instagram" href="#instagram"></use>
  </svg>
  <svg *ngSwitchCase="'mail'" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 474 474" style="enable-background:new 0 0 474 474;" [ngStyle]="{width, height}">
    <use xlink:href="#mail" href="#mail"></use>
  </svg>
  <svg *ngSwitchCase="'pinterest'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" [ngStyle]="{width, height}">
    <use xlink:href="#pinterest" href="#pinterest"></use>
  </svg>
  <svg *ngSwitchCase="'search'" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 489.713 489.713" style="enable-background:new 0 0 489.713 489.713;" [ngStyle]="{width, height}">
    <use xlink:href="#search" href="#search"></use>
  </svg>
  <svg *ngSwitchCase="'twitter'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" [ngStyle]="{width, height}">
    <use xlink:href="#twitter" href="#twitter"></use>
  </svg>
  <svg *ngSwitchCase="'feedback'" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" [ngStyle]="{width, height}" viewBox="0 0 512 512">
    <use xlink:href="#feedback" href="#feedback"></use>
  </svg>
  <svg *ngSwitchCase="'sort-icon'" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" [ngStyle]="{width, height}" viewBox="0 0 512 512">
    <use xlink:href="#sort-icon" href="#sort-icon"></use>
  </svg>
  <svg *ngSwitchCase="'youtube'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.57  20" [ngStyle]="{width, height}" preserveAspectRatio="xMidYMid meet">
    <use xlink:href="#youtube" href="#youtube"></use>
  </svg>
  <i *ngSwitchCaseDefault>{{shape}}</i>
</span>