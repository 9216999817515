import { Locale } from "./raw-data-types";

const LOCALES: Locale[] = ['en', 'id'];

export class PathsUtil {
  static getLocale(path: string): Locale {
    return PathsUtil.sanitizePath(path).split('/').filter(p => p)[0] as Locale || 'en';
  }

  static getHomePath(locale: string) {
    return `/${locale}`;
  }
  static isHomePath(path: string) {
    const sanitizedPath = this.sanitizePath(path);

    for (const locale of LOCALES) {
      if (sanitizedPath === `/${locale}`) {
        return true;
      }
    }

    return false;
  }

  static getAboutUsPath(locale: string) {
    return `/${locale}/about-us`;
  }
  static isAboutUsPath(path: string) {
    return PathsUtil.sanitizePath(path).includes('/about-us');
  }

  static getPrivacyPolicyPath(locale: string) {
    return `/${locale}/privacy-policy`;
  }
  static isPrivacyPolicyPath(path: string) {
    return PathsUtil.sanitizePath(path).includes('/privacy-policy');
  }

  static getCategoriesPath(locale: string) {
    return `/${locale}/categories`;
  }
  static isCategoriesPath(path: string) {
    return PathsUtil.sanitizePath(path).includes('/categories');
  }

  static getRecipePath(locale: string, recipeSlug: string) {
    return `/${locale}/recipe/${recipeSlug}`;
  }
  static getRecipeSlugFromPath(path: string) {
    const splitPath = PathsUtil.sanitizePath(path).split('/');
    const pivotIndex = splitPath.indexOf('recipe');
    return splitPath[pivotIndex + 1];
  }
  static isRecipePath(path: string) {
    return PathsUtil.sanitizePath(path).includes('/recipe/');
  }

  static get404Path(locale: string) {
    return `/${locale}/404`;
  }

  static getSearchPath(locale: string, query?: string, pageNumber?: number) {
    const queryParams: string[] = [];
    if (query) {
      queryParams.push(`q=${query}`);
    }
    if (pageNumber) {
      queryParams.push(`page=${pageNumber}`);
    }

    if (queryParams.length > 0) {
      return `${locale}/search?${queryParams.join('&')}`;
    }
    return `/${locale}/search`;
  }
  static isSearchPath(path: string) {
    return PathsUtil.sanitizePath(path).includes('/search');
  }

  static isBlogsPath(path: string) {
    return PathsUtil.sanitizePath(path).includes('/blogs');
  }
  static getBlogsPath(locale: string, pageNumber?: number) {
    const queryParams: string[] = [];
    if (pageNumber) {
      queryParams.push(`page=${pageNumber}`);
    }

    if (queryParams.length > 0) {
      return `/${locale}/blogs?${queryParams.join('&')}`;
    }
    return `/${locale}/blogs`;
  }
  static isBlogPath(path: string) {
    return PathsUtil.sanitizePath(path).includes('/blog/');
  }
  static getBlogPath(locale: string, blogSlug: string) {
    return `/${locale}/blog/${blogSlug}`;
  }
  static getBlogSlugFromPath(path: string) {
    const splitPath = PathsUtil.sanitizePath(path).split('/');
    const pivotIndex = splitPath.indexOf('blog');
    return splitPath[pivotIndex + 1];
  }

  /** 
   * Merges {@code path} with the specified query params.
   * 
   * The order of the query params are not defined.
   * 
   * {@code dirtyPath} may contain query params, which will be combined with the given queryParamMap. The query param in
   * queryParamMap has higher priority.
   */
  static constructPathWithQueryParams(dirtyPath: string, newQueryParamMap: { [key: string]: string }) {
    const pathAndQuerySplit = dirtyPath.split('?') || [];
    const cleanPath = pathAndQuerySplit[0] || '';
    const queryParamPairs = (pathAndQuerySplit[1] || '').split('&') || [];

    // Collect query params from path
    let queryParamMap: { [key: string]: string } = {};
    for (const pairStr of queryParamPairs) {
      const pair = (pairStr || '').split('=') || [];
      if (!pair[0] || !pair[1]) {
        // Invalid query param
        continue;
      }

      queryParamMap[pair[0]] = pair[1];
    }

    // Merge query params with the new query params
    queryParamMap = Object.assign({}, queryParamMap, newQueryParamMap);

    // Convert the map into list of strings
    const queryParams: string[] = [];
    for (const key in queryParamMap) {
      if (queryParamMap.hasOwnProperty(key)) {
        queryParams.push(`${key}=${queryParamMap[key]}`);
      }
    }

    // No query params
    if (queryParams.length === 0) {
      return cleanPath;
    }

    return `${cleanPath}?${queryParams.join('&')}`;
  }

  /**
   * Sanitizes a URL path:
   * - add the "/" prefix if it doesn't exist.
   * - remove the query parameters.
   * - remove hash values.
   */
  static sanitizePath(path: string): string {
    let result = path;
    if (!result.startsWith('/')) {
      result = '/' + result;
    }

    if (result.includes('?')) {
      result = result.split('?')[0];
    }

    if (result.includes('#')) {
      result = result.split('#')[0];
    }

    return result;
  }

  /** 
   * Stanitizes a URL path that conforms with firestore document ID requirement.
   * 
   * Ref: https://firebase.google.com/docs/firestore/quotas#collections_documents_and_fields
   */
  static sanitizePathForFirestoreDocId(path: string): string {
    return this.sanitizePath(path).replace(/\//g, '_').replace(/\./g, '_');
  }
}