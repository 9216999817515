// Ref: https://cloudinary.com/documentation/transformation_reference#overview
export interface CloudinaryAssetProperties {
  cloudName?: 'panomnom';
  assetType?: 'image';
  deliveryType?: 'upload' | 'authorized' | 'private';
  transformations?: string[];
  version?: number;
  publicId?: string;
}

export class CloudinaryUtils {
  private static readonly PANOMNOM_IMAGES_HOSTNAME = 'images.panomnom.com';

  static getBuildVersionQueryParam() {
    return 'bv';
  }
  static getDprQueryParam() {
    return 'dpr';
  }
  static getHeightQueryParam() {
    return 'height';
  }
  static getWebpSupportQueryParam() {
    return 'webp';
  }

  /** Constructs a Panomnom image URL from the {@link CloudinaryAssetProperties} instance. */
  static constructUrl(asset: CloudinaryAssetProperties, transformationConfig: { [key: string]: string }) {
    const transformationQueryParams: string[] = [];
    for (const k in transformationConfig) {
      transformationQueryParams.push(`${k}=${transformationConfig[k]}`);
    }

    let result = `https://${this.PANOMNOM_IMAGES_HOSTNAME}/${asset.deliveryType}/v${asset.version}/${asset.publicId}`;
    if (transformationQueryParams.length > 0) {
      result += `?${transformationQueryParams.join('&')}`;
    }

    return result;
  }

  /** 
   * Parses a panomnom or cloudinary {@link URL} to get the properties.
   * 
   * Example URLs:
   * - [cloudinary-host]/panomnom/image/upload/v123123/t_something/q_good/v123123/some/publicId.jpg
   * - [panomnom-image-host]/upload/v123123/t_something/q_good/v123123/some/publicId.jpg
   */
  static parseUrl(url: URL): CloudinaryAssetProperties {
    const pathSegments = (url.pathname || '').split('/').filter(x => x /* non-empty */);

    const assetProperties: CloudinaryAssetProperties = {};

    if (url.hostname === this.PANOMNOM_IMAGES_HOSTNAME) {
      assetProperties.cloudName = 'panomnom';
      assetProperties.assetType = 'image';
    }

    // Iterate through the segments with sequence: asset_type, delivery_type, transformations, version, publicId
    for (let i = 0; i < pathSegments.length; i++) {
      const pathSegment = pathSegments[i];

      // No cloud name yet, current path segment must be the cloud name.
      if (!assetProperties.cloudName) {
        assetProperties.cloudName = pathSegment as any;
        continue;
      }

      // No asset type yet, current path segment must be an asset type.
      if (!assetProperties.assetType) {
        assetProperties.assetType = pathSegment as any;
        continue;
      }

      // No delivery type yet, current path segment must be a delivery type.
      if (!assetProperties.deliveryType) {
        assetProperties.deliveryType = pathSegment as any;
        continue;
      }

      // No version and current path segment is not a version, so it must be a transformation.
      const versionPattern = /^v[0-9]{5}/;
      if (!assetProperties.version && !versionPattern.test(pathSegment)) {
        assetProperties.transformations ||= [];
        assetProperties.transformations.push(pathSegment);
        continue;
      }

      // No version, but current path segment is a version.
      if (!assetProperties.version) {
        assetProperties.version = Number(pathSegment.slice(1));
        continue;
      }

      // All other properties are filled, so the rest must be a public ID.
      assetProperties.publicId = decodeURIComponent(pathSegments.slice(i).join('/'));
      break;
    }

    return assetProperties;
  }
}