/**
 * Contains basic checks to determine the app environment.
 * 
 * The functions are safe to be used in the browser and node.js.
 */

/**
 * AppRealm constants that can be used to determine the app environment.
 */
export const AppRealm = {
  DEV_CLIENT: 'DEV_CLIENT', // React app running on localhost with firebase emulator
  DEV_SERVER: 'DEV_SERVER', // Cloud function (by firebase emulator) running on localhost
  PROD_CLIENT: 'PROD_CLIENT', // React app running on panomnom.com
  PROD_SERVER: 'PROD_SERVER', // Cloud function (in firebase, WITHOUT emulator) running on panomnom.com
  SSR_BUNDLE: 'SSR_BUNDLE', // During the SSR bundle generation.
}

/**
 * Gets the current app environment.
 */
export const getAppRealm = () => {
  if (typeof window === 'undefined') { // Server doesn't have window
    if (process.env['FUNCTIONS_EMULATOR'] === 'true') {
      return AppRealm.DEV_SERVER;
    }
    if (process.env['K_SERVICE'] || process.env['FUNCTION_NAME'] || process.env['GCP_PROJECT']) {
      return AppRealm.PROD_SERVER;
    }
    return AppRealm.SSR_BUNDLE;
  }

  if (window.location.hostname === 'localhost' || (window.location.hostname || '').startsWith('192.168.')) {
    return AppRealm.DEV_CLIENT;
  }

  return AppRealm.PROD_CLIENT;
};