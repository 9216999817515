import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { CloudinaryUtils } from '../../../st-recipes-definitions/cloudinary-utils';
import { AppRealm, getAppRealm } from './../../../shared/app-realm';

/**
 * Checks if the device has WEBP support or not.
 * 
 * Ref: https://stackoverflow.com/questions/5573096/detecting-webp-support
 * 
 * Intentionally put the value as a constant rather than a private method in the class to avoid calculating the value
 * over and over again (redundant, costs CPU and affects Time-to-Interactive score).
 */
const HAS_WEBP_SUPPORT = (() => {
  if (typeof document === 'undefined' || !document) {
    return false;
  }

  const elem = document.createElement('canvas');
  if (!!(elem.getContext && elem.getContext('2d'))) {
    // was able or not to get WebP representation
    return elem.toDataURL('image/webp').indexOf('data:image/webp') == 0;
  }

  // very old browser like IE 8, canvas not supported
  return false;
})();

@Component({
  standalone: true,
  selector: 'app-panomnom-image',
  templateUrl: './panomnom-image.component.html',
  styleUrls: ['./panomnom-image.component.scss'],
  imports: [
    CommonModule,
  ],
})
export class PanomnomImageComponent {
  @Input() useHeightForRender = true; // If true, `height` will also be used for the layout render calculation / style.
  @Input() height?: number;
  @Input() ratioWidth?: number;
  @Input() ratioHeight?: number;
  @Input() src = '';
  @Input() alt = '';
  panomnomImageUrl = 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='; // transparent image
  containerStyle: { [key: string]: string } = {};

  ngOnInit() {
    // The container height or ratio is derived from the props. Height is prioritized over ratio.
    // If there is no height or ratio, default to 4:3 aspect ratio.
    if (this.height && this.useHeightForRender) {
      this.containerStyle['height'] = `${this.height}px`;
    } else if (this.ratioWidth && this.ratioHeight) {
      this.containerStyle['paddingTop'] = `${this.ratioHeight / this.ratioWidth * 100}%`;
    } else {
      // default to use ratio calculation with 4:3 aspect ratio
      this.containerStyle['paddingTop'] = '75%';
    }

    // Only use the real image URL client-side, so that it won't take wrong DPR and Webp support values.
    // Let the placeholder / transparent image be used on server-side.
    if (getAppRealm() !== AppRealm.PROD_CLIENT && getAppRealm() !== AppRealm.DEV_CLIENT) {
      return;
    }

    // Gather transformation configs
    const transformationConfig: { [key: string]: string } = {
      [CloudinaryUtils.getDprQueryParam()]: `${this.getSupportedDpr()}`,
      [CloudinaryUtils.getBuildVersionQueryParam()]: '2', // Refer to panomnom images codebase
    };
    if (this.height) {
      transformationConfig[CloudinaryUtils.getHeightQueryParam()] = `${this.height}`;
    }
    if (HAS_WEBP_SUPPORT) {
      transformationConfig[CloudinaryUtils.getWebpSupportQueryParam()] = 'true';
    }

    const assetProperties = CloudinaryUtils.parseUrl(new URL(this.src));
    this.panomnomImageUrl = CloudinaryUtils.constructUrl(assetProperties, transformationConfig);
  }

  /** 
   * Gets window DPR value, clamped to the most common DPR values [1..4].
   * Ref: https://web.dev/articles/codelab-density-descriptors#windowdevicepixelratio
   */
  private getSupportedDpr() {
    if (typeof window === 'undefined' || !window.devicePixelRatio) {
      return 1; // Default DPR
    }

    return Math.max(1, Math.min(window.devicePixelRatio, 4));
  }
}
