import { Injectable } from "@angular/core";
import { ActivatedRoute } from '@angular/router';
import { PathsUtil } from 'st-recipes-definitions/paths-util';
import { Locale } from "st-recipes-definitions/raw-data-types";
import { CONSTANTS } from "../../shared/constants";

export const RoutePlaceholder = {
  LOCALE: "locale",
  BLOG_SLUG: "blog-slug",
  RECIPE_SLUG: "recipe",
};

/** 
 * An injectable helper related to app-routing.
 * 
 * In most use-cases, this helper should be provided under the routing components, otherwise the it won't be able to get
 * the correct route instance.
 */
@Injectable({
  providedIn: 'root',
})
export class AppRoutingHelper {
  constructor(private route: ActivatedRoute) { }

  getPath() {
    const p = this.route.snapshot.url.join('/');
    if (p.startsWith('/')) {
      return p;
    }
    return '/' + p;
  }

  getPathWithQueryParams() {
    const p = this.getPath();
    const queryParams = this.route.snapshot.queryParamMap.keys.map(k => `${k}=${this.route.snapshot.queryParamMap.get(k)}`).join('&');

    return `${p}?${queryParams}`;
  }

  getLocale(): Locale {
    return (this.route.snapshot.paramMap.get(RoutePlaceholder.LOCALE) || PathsUtil.getLocale(this.getPath())) as Locale;
  }

  getBlogSlug() {
    return this.route.snapshot.paramMap.get(RoutePlaceholder.BLOG_SLUG);
  }

  getRecipeSlug() {
    return this.route.snapshot.paramMap.get(RoutePlaceholder.RECIPE_SLUG);
  }

  getPathQueryParam() {
    return this.route.snapshot.queryParamMap.get(CONSTANTS.QUERY_PARAM_PATH) || '';
  }

  getSearchQueryParam() {
    return this.route.snapshot.queryParamMap.get(CONSTANTS.QUERY_PARAM_SEARCH_QUERY) || '';
  }

  getSearchSortOptionQueryParam() {
    return this.route.snapshot.queryParamMap.get(CONSTANTS.QUERY_PARAM_SORT_OPTION) || '';
  }

  getFilterTagIds(): string[] {
    return this.route.snapshot.queryParamMap.get(CONSTANTS.QUERY_PARAM_FILTER_TAGS_IDS)?.split(',').filter(i => /* non-empty values */ i.trim()) || [];
  }

  getPageNumberParam() {
    return Number(this.route.snapshot.queryParamMap.get(CONSTANTS.QUERY_PARAM_PAGE_NUMBER)) || 1;
  }

  redirectToUrl(url: string, replace: boolean = false) {
    if (typeof window !== 'undefined' && window && window.location) {
      if (replace && typeof window.location.replace === 'function') {
        window.location.replace(url);
      } else {
        window.location.href = url;
      }
      return;
    }

    console.error(`Failed to redirect to ${url}`);
  }
}